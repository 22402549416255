import React, { useEffect, useState } from 'react';
import StakingPage from './StakingPage';
import Login from '../Connect/Login';

export default function Staking(props) {

  const [starlets, setStarlets] = useState([]);
  const [golds, setGolds] = useState([]);
  const [platinums, setPlatinums] = useState([]);

  const fetch = () => {

  }

  return (
    <div className='container-staking'>
      {props.isAuthenticated
        ? <StakingPage wallet={props.wallet} starlets={starlets} golds={golds} platinums={platinums} />
        : <Login connect={props.connect} />
      }
    </div>
  )
}