import React from 'react';
import Select, { components } from 'react-select';
import countries from "../../Config/countries.json";
import ReactCountryFlag from "react-country-flag"



export default function CountrySelect(props) {

  const { Option } = components;
  const IconOption = c => (
    <Option {...c}>
      <ReactCountryFlag
          countryCode="c.data.value"
          style={{
              fontSize: '1rem',
              lineHeight: '1.2rem',
          }}
          aria-label={"Flag from " + c.data.text}
      />
      {c.data.text}
    </Option>
  );

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div className='country-select-option'>
      <ReactCountryFlag
          countryCode={value}
          style={{
              fontSize: '1rem',
              lineHeight: '1.2rem',
          }}
          aria-label={"Flag from " + label}
      />
      <div>{label}</div>
    </div>
  );

  return (
      <div className='country-select-div'>
          <Select 
            className='country-select'
            options={countries}
            onChange={(value) => {
              props.setCountry(value.value);
            }}
            formatOptionLabel={formatOptionLabel}
             />
      </div>
  ); 
}
