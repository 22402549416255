import React, { Component } from 'react'

export default class Login extends Component {
  render() {
    return (
      <div className='row'>
        <button className='btn' onClick={this.props.connect}>Connect</button>
      </div>
    )
  }
}
