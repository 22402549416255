import React, { useState } from 'react';
import StakingStarletItem from './StakingStarletItem';

export default function StakingStarlets(props) {

  const [starlets, setStarlets] = useState([]);

  return (
    <div className='starlets'>
      {starlets.map((item) => {
        <StakingStarletItem />
      })}
    </div>
  )
}
