import React, { Component } from 'react'
import Login from '../Connect/Login'
import MintForm from './MintForm'

export default class Minting extends Component {

  render() {
    if (this.props.paymentType == 'wallet') {
      return (
        <div className='container-minting'>
          {this.props.isAuthenticated
            ? <MintForm wallet={this.props.wallet} Moralis={this.props.Moralis} disconnect={this.props.disconnect} paymentType={this.props.paymentType} />
            : <Login connect={this.props.connect} />
          }
        </div>
      ) 
    } else {
      return (
        <div className='container-minting'>
          <div className='container'>
            <MintForm wallet={this.props.wallet} Moralis={this.props.Moralis} disconnect={this.props.disconnect} paymentType={this.props.paymentType} />
          </div>
        </div>
      )
    }
  }
}
