import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { useMoralis } from "react-moralis";

import Minting from "./Components/Minting/Minting";
import Staking from "./Components/Staking/Staking";
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {

  const theme = 'dark';
  const [wallet, setWallet] = useState({
    walletId: null
  })
  const { Moralis, authenticate, isInitialized, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
  const [ paymentType, setPaymentType ] = useState('wallet');

  useEffect(() => {
    if (isAuthenticated) {
      // add your logic here
      setWallet({
        walletId: user.get("ethAddress")
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const connect = async () => {
    if (!isAuthenticated) {

      await authenticate({signingMessage: "Log in using Moralis" })
        .then(function (user) {
          console.log("logged in user:", user);
          console.log(user.get("ethAddress"));
          setWallet({
            walletId: user.get("ethAddress")
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } 
  }

  const disconnect = async () => {
    await logout();
    console.log("logged out");
  }

  return (
    <div className={theme}>
      <Header isAuthenticated={isAuthenticated} disconnect={disconnect} />
      <Router>
        <Routes>
          <Route path="/"       element={<Minting paymentType={paymentType} setPaymentType={setPaymentType} wallet={wallet} connect={connect} disconnect={disconnect} isAuthenticated={isAuthenticated} Moralis={Moralis} />} />
          <Route path="/stake"  element={<Staking wallet={wallet} connect={connect} disconnect={disconnect} isAuthenticated={isAuthenticated} Moralis={Moralis} />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;