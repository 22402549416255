import React, { Component } from 'react'

export default class StakingMenu extends Component {
  render() {
    return (
      <div className='staking-menu'>
        <button>Stake</button>
        <button>Unstake</button>
        <button>Claim $DUST</button>
      </div>
    )
  }
}