import React, { Component } from 'react';
import Logout from './Connect/Logout';

export default class Header extends Component {
  render() {
    return (
      <div className='header'>
        <div className='header-logo'>
            <img src="/assets/img/logo1280x821.png" />
        </div>
      </div>
    )
  }
}
/*
  <div className='header-logout'>
    {this.props.isAuthenticated
      ? <>
          <Logout disconnect={this.props.disconnect} />
        </>
      : <></>
    }
  <div className='header-logout'>
*/