import React, { Component } from 'react'

export default class Connected extends Component {
  render() {
    return (
      <div className='connected'>
        Connected with wallet: {this.props.wallet.walletId} 
        <div className='disconnect' onClick={this.props.disconnect}>X</div>
      </div>
    )
  }
}
