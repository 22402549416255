import React from 'react'
import StakingStarlets from './StakingStarlets';
import StakingHoroscope from './StakingHoroscope';
import StakingMenu from './StakingMenu';
import StakingGold from './StakingGold';
import StakingPlatinum from './StakingPlatinum';

export default function StakingPage() {
  return (
    <div className='staking '>
      <div className='left'>
        <StakingMenu />
        <StakingStarlets />
      </div>
      <div className='right'>
        <StakingHoroscope />
        <StakingGold />
        <StakingPlatinum />
      </div>
    </div>
  )
}