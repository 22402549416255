import React, { useState } from 'react'
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import configData from "../../Config/config.json";

import ErrorMessage from '../ErrorMessage';
import { QuantityPicker } from 'react-qty-picker';
import Connected from '../Connect/Connected';
import CountrySelect from './CountrySelect';

export default function MintForm(props) {

  const decimals = 18;

  const [price, setPrice] = useState(0.5 * 10 ** decimals);
  const [quantity, setQuantity] = useState(1);
  const [country, setCountry] = useState(null);
  const [error, setError] = useState(null);
  const excludedCountries = ['AF','BY','BA','BI','CF','CG','CD','GN','GW','HT','IR','IQ','LB','LY','ML','ME','NG','RU','RS','SO','SD','SY','TN','TR','UA','VE','YE','ZW'];

  const mint = async () => {
    if (price == null) {
      setError("Price unknown");
      return false;
    }
    let web3 = await props.Moralis.enableWeb3();
    const contract = new web3.eth.Contract(configData.CONTRACT.ABI, configData.CONTRACT.ADDRESS);
    let receipt = await contract.methods
      .safeMint(quantity)
      .send({ from: props.wallet.walletId, value: 8620580000000000 })
      .then((response) => {
        // Check response and fix stuff
        console.log(response)
      })
      .catch((err) => setError(err.message));
  };

  const getPrice = (round) => {
    return quantity * price / 10 ** decimals;
  }

  const getPriceEth = (round) => {
    return quantity * price;
  }

  return (
    <div className='mint-form'>
        <div className='row'>
          <div className='nr_minted'>0 / 10000 minted</div>
        </div>
        <div className='row'>
          <div className='label'>Select your country</div>
          <CountrySelect country={country} setCountry={setCountry} />
        </div>
        <div className='row mint-quantity'>
          <div className='label'>Select your mint quantity</div>
        </div>
        <QuantityPicker smooth min={1} max={5} value={quantity} onChange={(value)=>{ setQuantity(value); }} name="quantity" />
        <div className='row'>
          <div className='label'>{getPrice()} ETH</div>
        </div>
        <div className='row'>
          {error && <ErrorMessage error={error} />}
          <button className='btn btn-default' onClick={mint}>Mint</button>
        </div>
        <div className='row'>
          <div className='connected'>
            <Connected wallet={props.wallet} disconnect={props.disconnect} />        
          </div>
        </div>
      </div>
  )
}
